
import React, { useEffect, useState } from 'react'
import { useApp } from '@/contexts/appContext';
import http from '@/lib/http';
import { BadRequestError, handleResponseError } from '@/utils/errors';
import { Spinner, useToast } from '@chakra-ui/react';
import { MdOutlineDirections } from "react-icons/md";
import { encryptMessage, getCardType, importPublicKey } from '@/utils/helpers';
import IframePopup from '@/components/IframePopup';
import { GiPadlock } from 'react-icons/gi';
import { armored_pub_key } from '@/utils/constants';

function Card() {

    const toast = useToast();
    const { info, setInfo } = useApp();
    const [isLoading, setIsLoading] = useState(false);
    const [cardData, setCardData] = useState({
        pan: "",
        expire_date: "",
        cvv: "",
        pin: ""
    })
    const [cardExtraData, setCardExtraData] = useState({
        paymentId: "",
        card_details: "",
        ACSUrl: "",
        TermUrl: "",
        JWT: "",
        md: "",
    })

    const [isUserBankAuthorizePaymentModalOpen, setIsUserBankAuthorizePaymentModalOpen] = useState(false);
    const [isUserBankAuthorizePaymentRunning, setIsUserBankAuthorizePaymentRunning] = useState(false);

    const [otp, setOtp] = useState("")

    const [tab, setTab] = useState<"card" | "otp" | "3ds">("card");

    function checkStatus(stopAfter: number = 6) {
        if (stopAfter <= 0) {
            // console.log("done");
            setIsLoading(false);
            toast({
                title: 'Error',
                description: "Payment timeout",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setTimeout(async () => {
            try {
                const resp = await http("wallet/collection/info", { order_reference_code: info?.order_reference_code });
                setInfo((e) => e !== null ? ({ ...e, payment_status: resp.data.payment_status }) : null);
                if (resp.data.payment_status === "Pending") {
                    checkStatus(stopAfter - 1);
                }
            } catch (error) {
                // console.log(error);
                handleResponseError(error, toast);
                checkStatus(stopAfter - 1);
            }
        }, 5000);
    }

    // check for payment status if user has closed partner modal
    useEffect(() => {
        if (!isUserBankAuthorizePaymentModalOpen && isUserBankAuthorizePaymentRunning) {
            // alert("payment modal closed");
            checkStatus(20);
            setIsUserBankAuthorizePaymentRunning(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserBankAuthorizePaymentRunning, isUserBankAuthorizePaymentModalOpen])

    // trigger modal
    async function AuthorizeWithUserBankPopUp() {
        if (!isUserBankAuthorizePaymentRunning) {
            setIsUserBankAuthorizePaymentRunning(true);
            setIsUserBankAuthorizePaymentModalOpen(true);
        } else {
            throw new BadRequestError("Payment is already running");
        }
    }

    async function handlePayWithCard() {
        setIsLoading(true);
        try {
            const pgp_pub_key = await importPublicKey(armored_pub_key);
            const encryptMessageResp = await encryptMessage(JSON.stringify({ meta: { ...cardData } }), pgp_pub_key);
            const resp = await http<{
                order_reference_code: string,
                authorization: "OTP" | "NONE" | "3DS",
                paymentId: string
            }>("wallet/collection/method/card/create", { order_reference_code: info?.order_reference_code, meta: { card_details: encryptMessageResp } });
            if (resp.data) {
                if (resp.data.authorization !== "NONE") {
                    if (resp.data.authorization === "OTP") {
                        setCardExtraData((d) => ({
                            ...d,
                            paymentId: resp.data.paymentId,
                            card_details: encryptMessageResp
                        }));
                        setIsLoading(false);
                        setTab("otp");
                    }
                    if (resp.data.authorization === "3DS") {
                        await handle3DS(resp.data, encryptMessageResp)
                    }
                } else {
                    checkStatus();
                }
            }
            // setTab("otp");
            // console.log(encryptMessageResp);
        } catch (error) {
            // console.log(error);
            handleResponseError(error, toast);
            setIsLoading(false);
        }
    }

    async function handlePayWithCardAuthorizeOtp() {
        setIsLoading(true);
        try {
            const resp = await http<{
                order_reference_code: string,
                authorization: "OTP" | "NONE" | "3DS"
            }>("wallet/collection/method/card/authorize-otp", {
                order_reference_code: info?.order_reference_code,
                meta: {
                    card_details: cardExtraData.card_details,
                    otp: otp,
                    paymentId: cardExtraData.paymentId
                }
            });
            if (resp.data) {
                if (resp.data.authorization === "NONE") {
                    checkStatus(20);
                } else {
                    if (resp.data === "3DS") {
                        await handle3DS(resp.data, cardExtraData.card_details)
                    }
                }
            }
        } catch (error) {
            setIsLoading(false);
            handleResponseError(error, toast);
            // console.log(error);
        }
    }

    async function handle3DS(data: {
        order_reference_code: string,
        authorization: string,
        paymentId: string,
        ACSUrl: string,
        TermUrl: string,
        JWT: string,
        md: string,

    }, card_details: string) {
        setCardExtraData({
            paymentId: data.paymentId,
            card_details: card_details,
            ACSUrl: data.ACSUrl,
            TermUrl: data.TermUrl,
            JWT: data.JWT,
            md: data.md
        })
        setTab("3ds");
        AuthorizeWithUserBankPopUp();
    }

    async function handleResendOtp() {
        setIsLoading(true);
        try {
            await http("wallet/collection/method/card/resend-otp", { order_reference_code: info?.order_reference_code, meta: { card_details: cardExtraData.card_details } });
            toast({
                title: 'Success',
                description: "OTP as been resent",
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            // console.log(error);
            handleResponseError(error, toast);
        } finally {
            setIsLoading(false);
        }
    }

    // Function to format the card number with spaces
    const formatCardNumber = (value: string): string => {
        // Remove all non-digit characters
        const rawValue = value.replace(/\D/g, '');
        // Add a space every 4 digits
        const formattedValue = rawValue.replace(/(\d{4})(?=\d)/g, '$1 ');
        return formattedValue.trim();
    };

    // Function to handle input changes
    const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.target;
        // Update the state with the raw value (no spaces)
        setCardData((prev) => ({
            ...prev,
            pan: value.replace(/\s+/g, ''), // store without spaces
        }));
    };

    // Function to format the expiry date
    const formatExpiryDate = (value: string): string => {
        // Ensure the input is at least 2 characters before inserting the slash
        if (value.length <= 2) {
            return value;
        } else {
            return value.slice(0, 2) + '/' + value.slice(2, 4); // Format as MM/YY
        }
    };

    // Function to handle input changes
    const handleExpiryDateChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.target;
        let rawValue = value.replace(/\D/g, ''); // Remove any non-digit characters

        // Limit to 4 digits (MMYY)
        if (rawValue.length > 4) {
            rawValue = rawValue.slice(0, 4);
        }

        setCardData((prev) => ({
            ...prev,
            expire_date: rawValue // Store as MMYY without slash
        }));
    };

    // Function to handle CVV input changes
    const handleCVVChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.target;
        // Allow only numbers, limit to 3 or 4 digits
        if (/^\d{0,4}$/.test(value)) {
            setCardData((prev) => ({
                ...prev,
                cvv: value,
            }));
        }
    };

    // Function to handle PIN input changes
    const handlePinChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.target;
        // Allow only numbers and limit the input to exactly 4 characters
        if (/^\d{0,4}$/.test(value)) {
            setCardData((prev) => ({
                ...prev,
                pin: value,
            }));
        }
    };

    // async function getInfo() {
    //     try {
    //         const resp = await http("wallet/collection/info", { order_reference_code: info?.order_reference_code });
    //         setInfo(resp.data);
    //         console.log(resp.data)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    function isPayWithCardButtonValid() {
        if (!isLoading && cardData.pan.length > 0 && cardData.cvv.length > 0 && cardData.expire_date.length > 0 && cardData.pin.length > 0) {
            return true
        }
        return false
    }

    return (
        <>
            {isUserBankAuthorizePaymentModalOpen && tab === "3ds" && <IframePopup data={{
                ACSUrl: cardExtraData.ACSUrl,
                JWT: cardExtraData.JWT,
                md: cardExtraData.md,
                TermUrl: cardExtraData.TermUrl
            }} setIsUserBankAuthorizePaymentModalOpen={setIsUserBankAuthorizePaymentModalOpen} setIsLoading={setIsLoading} />}
            <div>

                {tab === "card" ?
                    <>
                        <div className='flex justify-center w-full mb-5 text-xs md:text-sm md:mb-10'>
                            <div className='p-1 border-b-2 border-b-gray-900'>Enter card information to pay</div>
                        </div>
                        <div className='flex justify-center w-full'>
                            <div className='md:w-[80%] lg:w-[60%]'>
                                <div className='w-full p-2 mb-5 bg-gray-50'>
                                    <div className='flex items-center justify-between gap-3'>
                                        <div className='pl-2 text-xs font-bold text-neutral-800 text-opacity-85'>CARD NUMBER</div>
                                        <div className=''>
                                            {
                                                cardData.pan.length > 0 ?
                                                    getCardType(cardData.pan) === 'unknown' ? <></> :
                                                        <img className='w-[25px] md:w-[26px]' src={`/icons/${getCardType(cardData.pan)}.png`} alt="" />
                                                    :
                                                    <div className='flex items-center gap-1'>
                                                        <img className='w-[25px] md:w-[26px]' src="/icons/mastercard.png" alt="" />
                                                        <img className='w-[25px] md:w-[26px]' src="/icons/visa.png" alt="" />
                                                        <img className='w-[25px] md:w-[26px]' src="/icons/verve.png" alt="" />
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <input
                                        value={formatCardNumber(cardData.pan)}
                                        onChange={handleCardNumberChange}
                                        type="text"
                                        className='w-full p-2 bg-transparent outline-none'
                                        placeholder='0000 0000 0000 0000'
																				minLength={19}
                                        maxLength={23}
                                    />
                                </div>

                                <div className='flex justify-between gap-2 mb-5 md:gap-4'>
                                    <div className='w-full p-2 bg-gray-50'>
                                        <div className='pl-2 text-xs font-bold text-neutral-800 text-opacity-85'>CARD EXPIRY</div>
                                        <input
                                            value={formatExpiryDate(cardData.expire_date)}
                                            onChange={handleExpiryDateChange}
                                            type="text"
                                            className='w-full p-2 bg-transparent outline-none'
                                            placeholder='MM/YY'
                                            maxLength={5}
                                        />
                                    </div>

                                    <div className='w-full p-2 bg-gray-50'>
                                        <div className='pl-2 text-xs font-bold text-neutral-800 text-opacity-85'>CVV</div>
                                        <input
                                            value={cardData.cvv}
                                            onChange={handleCVVChange}
                                            type="text"
                                            className='w-full p-2 bg-transparent outline-none'
                                            placeholder='CVV'
                                            maxLength={4}
                                        />
                                    </div>
                                </div>

                                <div className='w-full p-2 mb-8 bg-gray-50'>
                                    <div className='pl-2 text-xs font-bold text-neutral-800 text-opacity-85'>Card PIN</div>
                                    <input
                                        value={cardData.pin}
                                        onChange={handlePinChange}
                                        type="password"
                                        className='w-full p-2 bg-transparent outline-none'
                                        placeholder='PIN'
                                        maxLength={4} // Limit to 4 characters
                                    />
                                </div>

                                <div className='mb-10'>
                                    <button onClick={() => handlePayWithCard()} type="button" className='w-full p-3 text-sm font-bold bg-gray-900 rounded-md disabled:bg-gray-900/60 text-neutral-50' disabled={!isPayWithCardButtonValid()}>Pay NGN {info!.amount}</button>
                                </div>

                                <div className='flex justify-center'>
                                    <div className='flex justify-between gap-3'>
                                        <GiPadlock className='w-4 h-4' />
                                        <div className='text-xs'>Secured by <b>MoiPayWay</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    tab === "otp" ?
                        <>
                            <div className='flex justify-center w-full mb-5 text-sm md:mb-10'>
                                <div className='p-1 text-center border-b-2 border-b-gray-900'>Enter OTP sent to your <br /> phone number or email</div>
                            </div>
                            <div className='flex justify-center w-full'>
                                <div>
                                    <div className='w-full p-2 mb-8 bg-gray-50'>
                                        <div className='pl-2 text-xs font-bold text-neutral-800 text-opacity-85'>OTP</div>
                                        <input
                                            value={otp}
                                            onChange={(e) => setOtp(e.target.value)}
                                            type="password"
                                            className='w-full p-2 bg-transparent outline-none'
                                            placeholder='OTP'
                                            maxLength={8}
                                        />
                                    </div>

                                    <div className='mb-8'>
                                        <button onClick={handlePayWithCardAuthorizeOtp} type="button" className='w-full p-3 text-sm font-bold bg-gray-900 rounded-md disabled:bg-gray-900/60 text-neutral-50' disabled={isLoading || otp.length < 4}>
                                        {
                                            !isLoading ?
                                            <span>Authorize</span> :
                                            <Spinner />
                                        }
                                        </button>
                                    </div>

                                    <div className='mb-10'>
                                        <div className='text-sm text-center'><span className='text-gray-900/60'>Didn't get OTP ?</span> <button onClick={() => handleResendOtp()} className="font-bold disabled:text-gray-900/60" disabled={isLoading}>Resend</button></div>
                                    </div>
                                </div>
                            </div>
                        </> :
                        <div className='flex justify-center w-full'>
                            <>
                                <div className='flex justify-center w-full mb-[200px]'>
                                    <div className='w-[300px] pt-[50px]'>
                                        <center>
                                            <div className='inline-block p-5 mb-5 text-gray-900 rounded-full bg-neutral-100'>
                                                <MdOutlineDirections className='w-8 h-8' />
                                            </div>
                                            <div className='mb-8 text-sm text-md'>You will be redirected to your financial institution page to completed this payment authorization</div>

                                            <div>
                                                <Spinner />
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            </>
                        </div>
                }
                {/* <div className='block md:hidden'>
                    <MobileMenu />
                </div> */}
            </div>
        </>
    )
}

export default Card